// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function App(Props) {
  return React.createElement("div", {
              className: "root"
            }, React.createElement("div", {
                  className: "leftcol"
                }, "Let Column"), React.createElement("div", {
                  className: "maincol"
                }, "Main Column"));
}

var make = App;

exports.make = make;
/* react Not a pure module */
